<template>
  <div class="setting">

    <div class="item">
      <el-form :model="info" ref="contactForm" :rules="baseRule" label-width="120px"
               class="item-form" size="small">
        <el-form-item label="企业微信编号" prop="appId">
          <el-input v-model="info.appId" placeholder="请输入商户简称，用于支付展示"></el-input>
        </el-form-item>
        <el-form-item label="企业微信应用编号" prop="agentId">
          <el-input v-model="info.agentId" placeholder="请输入客服电话"></el-input>
        </el-form-item>
        <el-form-item label="企业微信密钥" prop="appSecret">
          <el-input v-model="info.appSecret" placeholder="请输入管理员姓名"></el-input>
        </el-form-item>
        <el-form-item label="无支付链接地址" prop="notPayUrl">
          <el-input v-model="info.notPayUrl" placeholder="请输入管理员身份证号"></el-input>
        </el-form-item>

        <el-form-item label="咖博士经销商编号" prop="drBid">
          <el-input v-model="info.drBid" placeholder="请输入管理员手机号"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="handleCommit">保存</el-button>
        </el-form-item>

      </el-form>
    </div>

  </div>
</template>

<script>

  import {workSettingDetail, workSettingUpdate} from '@/api/work/setting'
  import {fillObj} from '@/util/checkLogin'

  export default {
    name: '',
    components: {},
    computed: {},
    mounted: function () {
      workSettingDetail().then(res => {
        fillObj(this.info, res.data)
      })
    },
    data: function () {
      return {
        checked: false,

        info: {
          appId: '',
          appSecret: '',
          agentId: '',
          notPayUrl: '',
          drBid: '',
        },
        baseRule: {
          appId: [
            {required: true, message: '请填写AppID', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],
          appSecret: [
            {required: true, message: '请填写AppSecret', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],
          agentId: [
            {required: true, message: '请填写AgentID', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],
          notPayUrl: [
            {required: true, message: '请填写支付链接地址', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],
          drBid: [
            {required: true, message: '请填写咖博士企业编号', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],
        },
      }
    },
    methods: {
      handleTestchange(val) {
        console.log(val)
      },
      fileUploadSuccess(data) {

      },
      handleCommit() {
        let hasValid = true


        this.$refs.contactForm.validate((valid) => {
          if (!valid) {
            hasValid = false
            return false;
          }
        })

        if (hasValid) {
          workSettingUpdate(this.info).then(res => {
            this.$message.success('更新成功')
          })
        }

        return;
      }
    },
  }
</script>

<style lang="scss">
  .setting {
    margin-top: $margin;
    padding: $padding 10%;

    .item {
      .item-title {
        margin-bottom: 20px;

        p {
          padding: 6px;
          background-color: #fff;
          border-bottom: 1px solid #e9eaec;
          font-size: 18px;
        }
      }

      .item-form {
        margin: 0 auto;
        width: 66.66666667%;

        .example {
          text-align: center;

          img {
            max-width: 100px;
          }
        }

        .explain {
          font-size: 12px;
          color: #999;
        }
      }

    }


  }
</style>
