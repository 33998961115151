import request from '@/util/request'


export const workSettingDetail = (params) => {
  return request({
    'url': '/api/v1/work/setting/detail',
    'method': 'GET',
    params
  })
}

export const workSettingUpdate = (data) => {
  return request({
    'url': '/api/v1/work/setting/update',
    'method': 'POST',
    data
  })
}
